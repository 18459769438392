import React from "react";
import { useOverrides, Override, Menu, Section } from "@quarkly/components";
import { Image, Box } from "@quarkly/widgets";
const defaultProps = {
	"font": "--headline1",
	"quarkly-title": "SectionHeader"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "12px 0",
			"justify-content": "space-between",
			"align-items": "center",
			"flex-direction": "row",
			"md-flex-direction": "column"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"width": "30%",
			"height": "64px",
			"padding": "0px 10px 0px 10px",
			"background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/vte_logo_en2.png?v=2021-01-14T09:28:54.706Z) 0% 0% /auto repeat scroll padding-box",
			"src": "https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/vte_logo_en2.png?v=2021-01-14T09:28:54.706Z"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"background": "rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/vte_logo_en2.png?v=2021-01-14T09:28:54.706Z) 0% 0% /auto no-repeat scroll padding-box"
		}
	},
	"menu": {
		"kind": "Menu",
		"props": {
			"display": "flex",
			"justify-content": "center",
			"font": "--base",
			"font-weight": "700",
			"md-flex-direction": "column",
			"md-align-items": "center"
		}
	},
	"menuOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"text-decoration": "none",
			"color": "--dark",
			"padding": "6px 12px"
		}
	},
	"menuOverride1": {
		"kind": "Override",
		"props": {
			"slot": "link-active",
			"color": "--primary"
		}
	},
	"menuOverride2": {
		"kind": "Override",
		"props": {
			"slot": "item",
			"padding": "6px"
		}
	},
	"menuOverride3": {
		"kind": "Override",
		"props": {
			"slot": "item-404",
			"display": "none"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Image {...override("image")} />
			<Box {...override("box1")} />
			<Menu {...override("menu")}>
				<Override {...override("menuOverride")} />
				<Override {...override("menuOverride1")} />
				<Override {...override("menuOverride2")} />
				<Override {...override("menuOverride3")} />
			</Menu>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;